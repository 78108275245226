import React, { useRef, useEffect, useLayoutEffect, useState, useCallback } from 'react';
import { OrgChart } from 'd3-org-chart';
import Button from 'components/Button';
import { ORGCHART_LAYER_COLORS } from 'lib/constants';

const OrgChartComponent = (props) => {
  const { data } = props;
  const nodeRef = useRef(null);
  const chartRef = useRef(null); // Persist chart instance across renders
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [singleRootNode, setSingleRootNode] = useState(true);
  const [dataValidation, setDataValidationFailed] = useState(true);

  // Ensure dimensions are set correctly based on the container size
  useLayoutEffect(() => {
    const updateDimensions = () => {
      setTimeout(() => {
        if (nodeRef.current) {
          const containerElement = nodeRef.current;
          const width = containerElement.offsetWidth;
          const height = containerElement.offsetHeight;
          setDimensions({
            width: width,
            height: height,
          });
        }
      }, 0);
    };

    updateDimensions();

    // Event listener for window resize
    window.addEventListener('resize', updateDimensions);

    // ResizeObserver to watch for changes in the size of the node element
    const resizeObserver = new ResizeObserver(updateDimensions);
    if (nodeRef.current) {
      resizeObserver.observe(nodeRef.current);
    }

    // Cleanup function to remove the event listener and disconnect ResizeObserver
    return () => {
      window.removeEventListener('resize', updateDimensions);
      if (nodeRef.current) {
        resizeObserver.unobserve(nodeRef.current);
      }
    };
  }, [nodeRef]);

  // Memoize the function for chart creation, useCallback ensures it's recreated only when necessary
  const createDiagram = useCallback(() => {
    const rootNodes = data.filter((item) => !item.parentNodeId);

    if (rootNodes.length !== 1) {
      setSingleRootNode(false);
      if (chartRef.current) {
        chartRef.current.clear();
      }
      return;
    }
    setSingleRootNode(true);

    try {
      if (!chartRef.current) {
        chartRef.current = new OrgChart();
      }

      chartRef.current
        .container(nodeRef.current)
        .data(data)
        .svgWidth(dimensions.width)
        .svgHeight(dimensions.height * 0.8)
        .initialZoom(0.8)
        .nodeWidth((d) => 300)
        .nodeHeight((d) => 250)
        .childrenMargin((d) => 40)
        .compact(false)
        .onNodeClick((d) => {
          console.log(d, 'Id of clicked node ');
          props.onNodeClick(d);
        })
        .nodeContent(function (d) {
          const colors = ORGCHART_LAYER_COLORS;
          const color = colors[d.depth % colors.length];
          return `
            <div style='position: relative; padding-top:30px; background-color:none; margin-left:1px; height:${d.height}px; overflow:visible'>
              <div style='height:${d.height}px; padding-top:0px; background-color:white; border:1px solid lightgray;border-bottom-left-radius:10px;border-bottom-right-radius:10px'>
                
                <div style='margin-top:-10px; background-color:${color}; height:10px; width:${d.width - 2}px;border-top-left-radius:10px;border-top-right-radius:10px'></div>

                <div style='padding:20px; padding-top:35px; text-align:center'>
                  ${d.data.employee_number ? `<div style='color:#111672; font-size:12px; font-weight: thin'>${d.data.employee_number}</div>` : ''}
                  ${d.data.name ? `<div style='color:#111672; font-size:20px; font-weight:bold; margin-top:5px'>${d.data.name}</div>` : ''}
                  ${d.data.role ? `<div style='color:#404040; font-size:16px; margin-top:15px'>${d.data.role}</div>` : ''}
                  ${d.data.unit ? `<div style='color:#111672; font-size:16px; font-weight:bold; margin-top:20px'>${d.data.unit}</div>` : ''}
                  ${d.data.fte ? `<div style='color:#111672; font-size:16px; font-weight:bold; margin-top:20px'>${d.data.fte} roles</div>` : ''}
                </div>

                <div style='position:absolute; bottom:0; width:100%; display:flex; justify-content:space-between; padding-left:15px; padding-right:15px;padding-bottom:15px'>
                  <div>Manages: ${d.data.directSubordinates} 👤</div> 
                  <div>Oversees: ${d.data.totalSubordinates} 👤</div>
                </div>
              </div>     
            </div>`;
        })
        .render();

      setTimeout(() => {
        chartRef.current.fit();
      }, 0);
    } catch (error) {
      setDataValidationFailed(true);
      console.error('Error creating org chart:', error);
      return;
    }
    setDataValidationFailed(false);
  }, [data, dimensions]);

  // Use effect to invoke the diagram creation only when necessary
  useEffect(() => {
    createDiagram();

    return () => {
      if (chartRef.current) {
        chartRef.current.clear();
      }
    };
  }, [data, dimensions, dataValidation]);

  return (
    <div>
      {!singleRootNode ? (  
        <div className="flex items-center justify-center h-screen text-center">
        <div style={{ maxWidth: '600px', padding: '20px', margin: '10px 0' }}>
          <h2 style={{ color: '#4963fe', marginBottom: '20px' }}>Duplicate root nodes</h2>
          <p style={{ marginBottom: '20px' }}>The organization chart Can only have one root node.</p>
          <p>Please review errors and correct them to generate your organization chart.</p>
          <p>Ensure that there is only one blank "Reporting to" value. That is the root of the organization chart.</p>
        </div>
      </div>
      ) : ( 
        dataValidation ? (
          <div className="flex items-center justify-center h-screen text-center">
            <div style={{ maxWidth: '600px', padding: '20px', margin: '10px 0' }}>
              <h2 style={{ color: '#4963fe', marginBottom: '20px' }}>Data Validation Error</h2>
              <p style={{ marginBottom: '20px' }}>There was a problem validating the data for the employee list.</p>
              <p>Please review errors and correct them to generate your organization chart.</p>
            </div>
          </div>
        ) : (
          <div style={{ position: 'relative' }}>
            <Button
              className='button mt-4 mb-4 w-auto'
              style={{ position: 'absolute', top: 0, right: 0 }}
              text='Download'
              onClick={() => chartRef?.current?.exportImg({ full: true })}
            />
          </div>
        ))}
        <div ref={nodeRef} style={{ height:'97vh' }}/>
    </div>
  );
};

export default OrgChartComponent;
