import React, { useState, useEffect } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Table from 'components/Table';
import Loading from 'components/Loading';

const CollapsibleEvaluationAgGrid = (props) => {
  const { objectName, evaluation, evaluationQuality, coverage, coverageQuality, List, setList, columnDefs, rowData, evaluationFunction, contextMenuGeneration, isLoading, isEvaluating, setIsEvaluating, validatedData } = props;
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    if (!validatedData) {
      return;
    }
    if (!collapsed && !isEvaluating && !evaluation && !coverage) {
      evaluationFunction();
      setIsEvaluating(true); 
    }
  }, [collapsed, isEvaluating, evaluation, coverage]);
  

  const toggleCollapse = () => {
    if (!validatedData) {
      alert('Please ensure data is valid before evaluating');
      return;
    }
    setCollapsed(!collapsed);
  };

  return (
    <div className='collapse-container'>
      <button onClick={toggleCollapse} className='container-wide-collapse-button'>
        {collapsed ? (
          <>
            Show Evaluation &#x25BC;
          </>
        ) : (
          <>
            Hide Evaluation &#x25B2;
          </>
        )}
      </button>
      {!collapsed && (
        !isEvaluating ? (
          <>
            <div className="">
              <button onClick={() => evaluationFunction()} disabled={isEvaluating} className="button ms-auto">
                Re-Evaluate {objectName}
                <span className="material-symbols-outlined">
                  neurology
                </span>
              </button>
              <div className="evaluation-card card__content-wrapper pd-2 mb-2">
                <h3>
                  <span style={{ color: evaluationQuality }}>●</span> Alignment between {objectName} and the strategy
                </h3>
                <p>{evaluation}</p>
              </div>

              <div className="evaluation-card card__content-wrapper pd-2 mb-2">
                <h3>
                  <span style={{ color: coverageQuality }}>●</span> {objectName} coverage of strategy
                </h3>
                <p>{coverage}</p>
              </div>
            </div>

            {/*<p>{designCriteriaEvaluation[0]?.['alignment_evaluation']}</p>
            <p>{designCriteriaEvaluation[0]?.['coverage_evaluation']}</p>
            <p>{designCriteriaEvaluation[0]?.['missing_aspects']}</p>
            <p>Design Criteria Score: {designCriteriaEvaluation[0]?.['score']}</p>*/}
        
          
          
            <div
              className="ag-theme-alpine ag-row card__content-wrapper"
              style={{ height: 'auto', width: '100%', marginTop: 10 }}
            >
              <h3>{objectName} Recommendations</h3>
              <Table 
                objectName={objectName}
                list={rowData}
                setList={setList}
                columnDefs={columnDefs}
                rowData={rowData}
                contextMenuGeneration={contextMenuGeneration? contextMenuGeneration : null}
                isLoading={isLoading}
              />
              
            </div>
          </>
        ) : (
          <div  >
              <Loading className="loading-spin" style={{marginTop: '0'}} text='AI is evaluating, just a moment' />
          </div>
        )
      )}
    </div>
  );
};

export default CollapsibleEvaluationAgGrid;
