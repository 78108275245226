import HTMLEditor from 'components/HTMLEditor';
import React, { useEffect, useState, useRef, useCallback } from 'react';

import { Col, Row } from 'reactstrap';
import Loading from 'components/Loading';
import { postSearchApi } from 'api/chat';


export default function StrategyTab({ client, name, website, businessDetails, missionStatement, 
  strategyArena, setStrategyArena, strategyVehicle, setStrategyVehicle, strategyDifferentiator, 
  setStrategyDifferentiator, strategyStaging, setStrategyStaging, 
  strategyEconomicLogic, setStrategyEconomicLogic }) {
  const [isGeneratingStrategyArena, setIsGeneratingStrategyArena] = useState(false);
  const [isGeneratingStrategyVehicle, setIsGeneratingStrategyVehicle] = useState(false);
  const [isGeneratingStrategyDifferentiator, setIsGeneratingStrategyDifferentiator] = useState(false);
  const [isGeneratingStrategyStaging, setIsGeneratingStrategyStaging] = useState(false);
  const [isGeneratingStrategyEconomicLogic, setIsGeneratingStrategyEconomicLogic] = useState(false);
  const [errors, setErrors] = useState({ name: false, website: false });
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);

  const generateStrategyComponent = useCallback(async (strategyComponent, setIsGeneratingStrategy, setStrategyComponent) => {
    if (!strategyComponent) {
      return
    }
    setWarningModalIsOpen(false);
    const newErrors = { name: !name, website: !website };
    setErrors(newErrors);
    if (newErrors.name || newErrors.website) {
      return;
    }
    
    setIsGeneratingStrategy(true);

    const apiPayload = {
        'prompt_template': 'generate_strategy_component',
        'component': strategyComponent,
        'client': client,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'mission_statement': missionStatement ? missionStatement : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'strategy_arena': strategyArena ? strategyArena : '',
        'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
        'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
        'strategy_staging': strategyStaging ? strategyStaging : '',
        'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const generatedStrategyComponent = apiResponse.message;

      try {
        
        setStrategyComponent(generatedStrategyComponent);
        //setStrategyArena(generatedStrategyArena);
        setIsGeneratingStrategy(false);
      } catch (error) {
          console.error('Error parsing API response:', error);
          setIsGeneratingStrategy(false);
      }

    } catch (error) {
        console.error('Error generating strategy component ' + strategyComponent + ':', error);
    }
  }, [client, name, website, missionStatement, businessDetails, strategyArena, strategyVehicle, strategyDifferentiator, strategyStaging, strategyEconomicLogic]);

  

  const StrategyHtmlEditor = ({ strategyComponent, editorLabel, generateButtonLabel, htmlEditorValue, setHtmlEditorValue, loadingText, isGeneratingStrategy, setIsGeneratingStrategy }) => {
    const handleGenerate = useCallback(() => {
      if (!name || !website) {
        alert('Please fill in the business name and website before generating a strategy.');
        return;
      }
      generateStrategyComponent(strategyComponent, setIsGeneratingStrategy, setHtmlEditorValue);
    }, [strategyComponent, setIsGeneratingStrategy, setHtmlEditorValue]);
    
    return (
      <Col sm="6" className=''>
          <div className='mb-5'>
            <Row>
              <Col sm="6" className=''>
              <div className="form-header">
                {editorLabel}
              </div>
              </Col>
              <Col sm="6" className=''>
              <button onClick={handleGenerate} disabled={ isGeneratingStrategy } className="button me-3 mb-3 w-auto float-end">
                  {generateButtonLabel}
                  <span className="material-symbols-outlined">
                    neurology
                  </span>
              </button>

                
                {// TODO: Use this verison - This is a version of the button that warns user that data will be overwritten
                /*<button onClick={() => checkIfEmptyStrategy() ? generateStrategyComponent(
                  strategyComponent,
                  setIsGeneratingStrategy,
                  setHtmlEditorValue
                ) : setWarningModalIsOpen(true)} disabled={ isGeneratingStrategy } className="button me-3 mb-3 w-auto float-start">
                  {generateButtonLabel}
                  <span className="material-symbols-outlined">
                    neurology
                  </span>
              </button>*/}
              </Col>
            </Row>
            {isGeneratingStrategy ? (
              <div className="loading-spin pb-5">
                <Loading text={loadingText} />
              </div>
            ) : (
              <HTMLEditor
                value={htmlEditorValue}
                onChange={(newValue) => {
                  setHtmlEditorValue(newValue);
                }}
                placeholder="Start writing, or click the generate button to have Reconfig AI write a draft for you..."
              />
            )}
          </div>
        </Col>
    );
  };

  return (
    <div>
      <div className="function-tab-header">
      </div>
      <div className="card-content mb-2">
        <Row className="mt-2">
          <Row className="mt-2 mb-1">
          <StrategyHtmlEditor 
            strategyComponent="strategy_arena"
            generateButtonLabel="Generate arena suggestions" 
            editorLabel="Arenas: Where will you be active?" 
            htmlEditorValue={strategyArena}
            setHtmlEditorValue={setStrategyArena} 
            loadingText="AI is proposing strategic arenas for your business..."
            isGeneratingStrategy={isGeneratingStrategyArena}
            setIsGeneratingStrategy={setIsGeneratingStrategyArena}
          />
            
          <StrategyHtmlEditor 
            strategyComponent="strategy_vehicle"
            generateButtonLabel="Generate vehicle suggestions" 
            editorLabel="Vehicles: How will you get there?" 
            htmlEditorValue={strategyVehicle} 
            setHtmlEditorValue={setStrategyVehicle} 
            loadingText="AI is proposing strategic vehicles for your business..."
            isGeneratingStrategy={isGeneratingStrategyVehicle}
            setIsGeneratingStrategy={setIsGeneratingStrategyVehicle}
          />
            
          </Row>
          <Row className="mt-1">
            <StrategyHtmlEditor 
              strategyComponent="strategy_differentiators"
              generateButtonLabel="Generate differentiators suggestions" 
              editorLabel="Differentiators: how will you win in the marketplace?" 
              htmlEditorValue={strategyDifferentiator} 
              setHtmlEditorValue={setStrategyDifferentiator} 
              loadingText="AI is proposing strategic differentiators for your business..."
              isGeneratingStrategy={isGeneratingStrategyDifferentiator}
              setIsGeneratingStrategy={setIsGeneratingStrategyDifferentiator}
            />
            <StrategyHtmlEditor 
              strategyComponent="strategy_staging"
              generateButtonLabel="Generate staging suggestions" 
              editorLabel="Staging: What steps do you need to take over what time horizon?" 
              htmlEditorValue={strategyStaging} 
              setHtmlEditorValue={setStrategyStaging} 
              loadingText="AI is proposing strategic staging for your business..."
              isGeneratingStrategy={isGeneratingStrategyStaging}
              setIsGeneratingStrategy={setIsGeneratingStrategyStaging}
            />
          </Row>
          <Row className="mt-1">
            <StrategyHtmlEditor 
              strategyComponent="strategy_economic_logic"
              generateButtonLabel="Generate economic logic suggestions" 
              editorLabel="Economic Logic: How will you generate returns?" 
              htmlEditorValue={strategyEconomicLogic} 
              setHtmlEditorValue={setStrategyEconomicLogic} 
              loadingText="AI is proposing strategic economic logic for your business..."
              isGeneratingStrategy={isGeneratingStrategyEconomicLogic}
              setIsGeneratingStrategy={setIsGeneratingStrategyEconomicLogic}
            />
          </Row>
        </Row>
      {/*{warningModalIsOpen && (
        <Modal isOpen={warningModalIsOpen} toggle={() => setWarningModalIsOpen(false)}>
          <ModalBody className='text-center p-3'>
            <div className="popup-warning-icon">
              <span className="material-symbols-outlined popup-warning-icon">
                warning
              </span>
            </div>
            <div className="popup-warning-header">
              Proceeding will overwrite your current strategy.
            </div>
            <div className="popup-warning-header">
              Are you certain you wish to continue?
            </div>
          </ModalBody>
          <ModalFooter>
            <button onClick={() => generateStrategyComponent()} className="button ms-auto">
              Generate new strategy
              <span className="material-symbols-outlined">
                neurology
              </span>
            </button>
            <Button
              text="Cancel"
              onClick={() => setWarningModalIsOpen(false)}
              className="button ms-3"
            />
          </ModalFooter>
        </Modal>
      )}*/}
      </div>
    </div>
  );
}
