import React, { useEffect, useState, useRef } from 'react';
import { Col, Row } from 'reactstrap';
import { isEmptyObject } from 'lib/helper';
import Button from 'components/Button';
import { AgGridReact } from 'ag-grid-react';
import Loading from 'components/Loading';
import {
  deleteClient,
  editClient,
} from 'api/user';
import toast from 'lib/toast';
import Modal from './Modal';


export default function ClientAdminTab({ refreshClientList, clientList }) {
  const [showModal, setShowModal] = useState(false);
  const [modalName, setModalName] = useState('Delete');
  const [selectedId, setSelectedId] = useState(undefined);

  const data = clientList.filter(c => c.id === selectedId)[0];

  const onSelect = (clientId) => {
    setSelectedId(clientId);
  };
  const onDelete = () => {
    setShowModal(true);
    setModalName('Delete');
  };
  const onUpdate = () => {
    setShowModal(true);
    setModalName('Update');
  };
  const onClose = () => {
    setShowModal(false);
  }
  const onConfirm = (data) => {
    if (modalName === 'Delete') {
      deleteClient({client_id: selectedId}).then(
        res => {
          setShowModal(false);
          refreshClientList();
        }
      ).catch(e => {
        toast('Failed to delete client', e.response?.data?.msg);
      });
    } else if (modalName === 'Update') {
      editClient({user_id: selectedId, ...data}).then(
        res => {
          setShowModal(false);
          refreshClientList();
        }
      ).catch(e => {
        toast('Failed to update user', e.response?.data?.msg);
      });
    }
  };

  const ActionCell = (props) => {
    const onDelete = () => {
      props.context.select(props.data.id);
      props.context.delete();
    };
  
    const onEdit = () => {
      props.context.select(props.data.id);
      props.context.update();
    };
  
    if (props.data.isSelf) return <></>;
  
    return (
      <div style={{ display: 'inline-flex' }}>
        <button className="form-control" onClick={onEdit}>
          Edit
        </button>
        <button className="form-control ms-2" onClick={onDelete}>
          Delete
        </button>
      </div>
    );
  };

  const ClientColumnDefs = [
    {
      headerName: 'Client name',
      headerTooltip: 'The name of the client',
      field: 'name',
      flex: 1,
    },
    {
      headerName: 'Created',
      headerTooltip: 'The date the client was created',
      field: 'created',
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const localDate = date.toDateString();
        return `${localDate}`;
      },
      flex: 1,
    },
    {
      headerName: 'Actions',
      headerTooltip: 'action',
      field: 'action',
      flex: 1,
      cellRenderer: ActionCell,
    },
  ];

  return (
    <div className="admin-tab">
      <div className="function-tab-header">
        <h3>Client Administration</h3>
      </div>
      <div className="card-content mb-2">
        <div
          className="ag-theme-alpine table-bar"
          style={{ height: 500, width: 'auto' }}
        >
          <AgGridReact
            rowData={clientList}
            defaultColDef={{
              width: 140,
              minWidth: 140,
              sortable: true,
              resizable: true,
            }}
            columnDefs={ClientColumnDefs}
            enableBrowserTooltips={true}
            context={{
              delete: onDelete,
              update: onUpdate,
              select: onSelect,
            }}
          />
        </div>
        
        <Modal
          showModal={showModal}
          modalName={modalName}
          onClose={onClose}
          onConfirm={onConfirm}
          user={data}
          objectName="client"
        />
      </div>
    </div>
  );
}
