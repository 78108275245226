import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import {
  deleteUser,
  editUser,
  getUserList,
  inviteUserList,
  updateProfile,
} from 'api/user';
import ApiHelper from 'api/helper';
import toast from 'lib/toast';
import { getProfileData } from './profileSlice';
import ClientAdminTab from './ClientAdminTab';
import UserAdminTab from './UserAdminTab';


const Profile = ({ ...props }) => {
  const { profile } = props;
  const [formData, setFormData] = useState({});
  const [clientList, setClientList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalName, setModalName] = useState('Delete');
  const [activeTab, setActiveTab] = useState('UserAdminTab');

  useEffect(() => {
    const {
      first_name,
      last_name,
      email,
      company,
    } = profile || {};
    setFormData({
      first_name,
      last_name,
      email,
      company,
    });

    if (profile?.is_admin) {
      refreshUserList();
    }
  }, [profile]);

  
  const refreshUserList = () => {
    getUserList().then(
      data => {
        setUserList(data.map(user => {
          return {
            ...user,
            isSelf: user.user__id === profile.id,
          };
        }));
      },
    ).catch(e => {
      console.warn(e);
    });
  };

  const refreshClientList = () => {
    ApiHelper.get('/api/clients/').then((clientObjectsList) => {
      const client_queryset = clientObjectsList.data;
      setClientList(client_queryset);
    });
  };

  useEffect(() => {
    refreshClientList();
  }, [props.currentClient]);

  function onFormChange(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  function onFormSubmit(e) {
    e.preventDefault();
    updateProfile(formData)
      .then(data => {
        if (data.success) {
          toast('Updated profile successfully', data.msg);
        }
        props.getProfileData();
      })
      .catch(e => {
        toast('Failed to update profile', e.response?.data?.msg);
      })
      .finally(() => null);
  }  

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  const onModalToggle = () => {
    setShowModal(!showModal);
  }

  return (
    <>
      <div className="content__header pt-3 mt-3 mb-3">
        <h2>Welcome {profile?.first_name || ''}</h2>
      </div>
      <div className="card">
        <div className="card__content-wrapper">
          <div className="card-content">
            <Form onSubmit={onFormSubmit} className="login-form">
              <Row>
                <FormGroup className="col-md-6">
                  <label htmlFor="first_name">First Name</label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    className="form-control"
                    placeholder="First Name"
                    value={formData.first_name || ''}
                    onChange={onFormChange}
                  />
                </FormGroup>
                <FormGroup className="col-md-6">
                  <label htmlFor="last_name">Last Name</label>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    className="form-control"
                    placeholder="Last Name"
                    value={formData.last_name || ''}
                    onChange={onFormChange}
                  />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup className="col-md-6">
                  <label htmlFor="company">Company</label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    className="form-control"
                    placeholder="Company"
                    value={formData.company || ''}
                    onChange={onFormChange}
                  />
                </FormGroup>
                <FormGroup className="col-md-6">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={formData.email || ''}
                    className="form-control"
                    placeholder="Email"
                    onChange={onFormChange}
                  />
                </FormGroup>
              </Row>
              <button
                type="submit"
                className="button login-button mb-3 float-end"
              >
                Update profile details
              </button>
            </Form>
          </div>
        </div>
      </div>
      {
        profile?.is_admin && (
          <>
            <Nav tabs className='ms-0'>
              <NavItem>
                <NavLink
                  active={activeTab === 'UserAdminTab'}
                  onClick={() => changeTab('UserAdminTab')}
                  className="strategy-tab">
                  Users
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === 'ClientAdminTab'}
                  onClick={() => changeTab('ClientAdminTab')}
                  className="strategy-tab">
                  Clients
                </NavLink>
              </NavItem>
            </Nav>
            <div className="card__group">
              <div className="card">
                <div className="card__content-wrapper">
                  
                  
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="UserAdminTab">
                      <UserAdminTab 
                        userList={userList}
                        setUserList={setUserList}
                        refreshUserList={refreshUserList}
                        profile={profile}
                        formData={formData}
                      />
                    </TabPane>
                    <TabPane tabId="ClientAdminTab">
                      <ClientAdminTab 
                        clientList={clientList}
                        refreshClientList={refreshClientList}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = state => ({
  profile: state.profile.data,
});
const mapDispatchToProps = {
  getProfileData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
