import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import {
  deleteUser,
  editUser,
  getUserList,
  inviteUserList,
  updateProfile,
} from 'api/user';
import toast from 'lib/toast';
import Modal from './Modal';


export default function UserAdminTab({ ...props }) {
  const { userList, setUserList, refreshUserList, profile, formData } = props;
  const [selectedId, setSelectedId] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [modalName, setModalName] = useState('Delete');

  const data = userList.filter(u => u.id === selectedId)[0];
    
    const onClickInvite = () => {
        setModalName('Invite');
        setShowModal(true);
    };
    const onSelect = (userId) => {
        setSelectedId(userId);
    };
    const onDelete = () => {
        setShowModal(true);
        setModalName('Delete');
    };
    const onUpdate = () => {
        setShowModal(true);
        setModalName('Update');
    };
    const onClose = () => {
        setShowModal(false);
    }
    const onConfirm = (data) => {
        if (modalName === 'Delete') {
            deleteUser({user_id: selectedId}).then(
                res => {
                setShowModal(false);
                refreshUserList();
                }
        ).catch(e => {
            toast('Failed to remove user', e.response?.data?.msg);
        });
        } else if (modalName === 'Invite') {
        inviteUserList(data).then(
            res => {
            setShowModal(false);
            refreshUserList();
            }
        ).catch(e => {
            toast('Failed to invite user', e.response?.data?.msg);
        });
        } else if (modalName === 'Update') {
        editUser({user_id: selectedId, ...data}).then(
            res => {
            setShowModal(false);
            refreshUserList();
            }
        ).catch(e => {
            toast('Failed to update user', e.response?.data?.msg);
        });
        }
    };

    function onFormSubmit(e) {
        e.preventDefault();
        updateProfile(formData)
        .then(data => {
            if (data.success) {
            toast('Updated profile successfully', data.msg);
            }
            props.getProfileData();
        })
        .catch(e => {
            toast('Failed to update profile', e.response?.data?.msg);
        })
        .finally(() => null);
    }

    const ActionCell = (props) => {
        const onDelete = () => {
        props.context.select(props.data.id);
        props.context.delete();
        };
    
        const onEdit = () => {
        props.context.select(props.data.id);
        props.context.update();
        };
    
        if (props.data.isSelf) return <></>;
    
        return (
        <div style={{ display: 'inline-flex' }}>
            <button className="form-control" onClick={onEdit}>
            Edit
            </button>
            <button className="form-control" onClick={onDelete}>
            Delete
            </button>
        </div>
        );
    };
    const accessLevelFormatter = (params) => {
        return params.value ? 'Admin' : 'User';
    };
    const loginDateFormatter = (params) => {
        return params.value ? new Date(params.value).toDateString() : 'Never';
    };
    const UserColumnDefs = [
        {
        headerName: 'First Name',
        headerTooltip: 'user__first_name',
        field: 'user__first_name',
        flex: 1,
        },
        {
        headerName: 'Last Name',
        headerTooltip: 'user__last_name',
        field: 'user__last_name',
        flex: 1,
        },
        {
        headerName: 'Email',
        headerTooltip: 'user__email',
        field: 'user__email',
        flex: 1,
        },
        {
        headerName: 'Access Level',
        headerTooltip: 'is_admin',
        field: 'is_admin',
        flex: 1,
        valueFormatter: accessLevelFormatter,
        },
        {
        headerName: 'Last Login',
        headerTooltip: 'user__last_login',
        field: 'user__last_login',
        valueFormatter: loginDateFormatter,
        flex: 1,
        },
        {
        headerName: 'Actions',
        headerTooltip: 'action',
        field: 'action',
        flex: 1,
        cellRenderer: ActionCell,
        },
    ];

    return (
        <div className="admin-tab">
            <div className="function-tab-header">
                <h3>User Administration</h3>
                <button
                    className="button ms-auto"
                    onClick={onClickInvite}
                >
                Invite new User
                </button>
            </div>
            <div className="card-content">
                <div
                className="ag-theme-alpine table-bar"
                style={{ height: 500, width: 'auto' }}
                >
                <AgGridReact
                    rowData={userList}
                    defaultColDef={{
                    width: 140,
                    minWidth: 140,
                    sortable: true,
                    resizable: true,
                    }}
                    columnDefs={UserColumnDefs}
                    enableBrowserTooltips={true}
                    context={{
                    delete: onDelete,
                    update: onUpdate,
                    select: onSelect,
                    }}
                />
                </div>
            </div>
            <Modal
              showModal={showModal}
              modalName={modalName}
              onClose={onClose}
              onConfirm={onConfirm}
              user={data}
              objectName="user"
            />
        </div>
        )
    }