import React, { useCallback, useMemo, useEffect, useState, useReducer, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Button from 'components/Button';
import _, { result, split } from 'lodash';
import { Modal, ModalBody, ModalFooter, ModalHeader, Container, Col, Row } from 'reactstrap';
import SVG from 'components/SVG';
import AggridContextMenu from 'components/AggridContextMenu';
import ClassificationDropdownEditor from 'components/ClassificationDropdownEditor';
import ExportToExcel from 'lib/ExportToExcel';
import CSVWizard from 'pages/Survey/components/CSVWizard';
import { generateuniqueIshId, listReducer, transposeObject, sum } from 'lib/helper';
import { postSearchApi } from 'api/chat';
import Loading from 'components/Loading';
import LoadingCellRenderer from 'components/LoadingCellRenderer';
import ArrayCellRenderer from 'components/ArrayCellRenderer';
import DraggableListCellRenderer from 'components/DraggableListCellRenderer';
import Table from 'components/Table';
import CollapsibleEvaluationAgGrid from 'components/CollapsibleAgGrid ';
import DeleteRowButtonRenderer from 'components/ButtonRenderer/DeleteRowButtonRenderer';
import { MultiSelectCellRenderer, MultiSelectCellEditor, MultiSelectCell } from 'components/Table/MultiselectCellRenderers';
import { validateAgGridColumn } from 'lib/validation';

const RoleTab = (props) => {
  const { client, name, website, businessDetails, missionStatement, 
    strategyArena, strategyVehicle, strategyDifferentiator, 
    strategyStaging, strategyEconomicLogic, designCriteria, blueprint, 
    functions, organizationUnits, roles, setRoles, addNewRole } = props;

  const [isGeneratingRoles, setIsGeneratingUnits] = useState(false);
  const [isEvaluatingRoles, setIsEvaluatingRoles] = useState(false);
  const [rolesEvaluation, setRolesEvaluation] = useState({});
  const [showImport, setShowImport] = useState(false);
  const [isLoading, setIsLoading] = useReducer(listReducer, []);

  const functionMapping = functions.map((f) => ({ label: f.name, value: f.id }));

  const baseColumnConfig = useMemo(() => ({
    editable: true,
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    cellClass: '.ag-cell-prevent-word-split',
  }), []);

  const setRoleFunctions = (roleId, newFunctions) => {
    const role = roles.find((role) => role.id === roleId);
    const newRole = { ...role, functions: newFunctions };
    setRoles({ type: 'update', payload: newRole });
  };

  const baseColumnDefs = useMemo(() => [
    { headerName: 'ID', field: 'id', hide: true, ...baseColumnConfig },
    { headerName: '', field: 'emoji', ...baseColumnConfig, width: 30, flex: 0 },
    { headerName: 'Name', field: 'name', 
      cellRendererFramework: LoadingCellRenderer, 
      cellClassRules: {
        'validation-error': (params) => !params.value || params.value.trim() === ''
      }, 
      valueSetter: (params) => validateAgGridColumn(params, 'name'), 
      ...baseColumnConfig },
    { headerName: 'Abbreviation', field: 'abbreviation', 
      cellRendererFramework: LoadingCellRenderer, 
      cellClassRules: {
        'validation-error': (params) => !params.value || params.value.trim() === ''
      }, 
      valueSetter: (params) => validateAgGridColumn(params, 'name'), 
      ...baseColumnConfig, 
      width: 60, 
      flex: 0 },
    { headerName: 'Purpose', field: 'purpose', 
      cellRendererFramework: LoadingCellRenderer, 
      cellClassRules: {
        'validation-error': (params) => !params.value || params.value.trim() === ''
      }, 
      valueSetter: (params) => validateAgGridColumn(params, 'name'), 
      wrapText: true, 
      ...baseColumnConfig, 
      flex: 3 },
    { headerName: 'Key Responsibilities', field: 'responsibilities', 
      cellRendererFramework: LoadingCellRenderer, 
      cellClassRules: {
        'validation-error': (params) => !params.value || params.value.trim() === ''
      }, 
      valueSetter: (params) => validateAgGridColumn(params, 'name'), 
      wrapText: true, 
      ...baseColumnConfig, 
      flex: 3 },
  ], [baseColumnConfig]);

  const roleColumns = useMemo(() => [
    ...baseColumnDefs,
    { 
      headerName: '', 
      field: 'delete_row', 
      cellRenderer: params => <DeleteRowButtonRenderer {...params} list={roles} setList={setRoles} />, 
      cellClass: 'delete-row-button-cell', 
      width: 50, 
      flex: 0 
    }
  ], [baseColumnDefs, roles, setRoles]);

  const evaluationColumns = useMemo(() => [
    ...baseColumnDefs,
    { 
      headerName: '', 
      field: 'add_row', 
      cellRenderer: params => (
        <KeepRowButtonRenderer 
          {...params} 
          state={roles} 
          setData={setRoles} 
          evaluation={rolesEvaluation} 
          setEvaluation={setRolesEvaluation} 
        />
      ), 
      cellClass: 'add-row-button-cell', 
      width: 50, 
      flex: 0 
    }
  ], [baseColumnDefs, roles, setRoles, rolesEvaluation, setRolesEvaluation]);
  
  const rowData = useMemo(() => {
    if (Array.isArray(roles)) {
      return roles.map(role => ({
        id: role.id,
        name: role.name,
        abbreviation: role.abbreviation,
        purpose: role.purpose,
        responsibilities: role.responsibilities,
        functions: role.functions,
        emoji: role.emoji,
      }));
    }
    return [];
  }, [roles]);

  const evaluation_rowData = useMemo(() => {
    if (Array.isArray(rolesEvaluation?.recommended_roles)) {
      return rolesEvaluation?.recommended_roles.map(role => ({
        id: role.id,
        name: role.name,
        abbreviation: role.abbreviation,
        purpose: role.purpose,
        functions: role.functions,
        responsibilities: role.responsibilities,
        emoji: role.emoji,
      }));
    }
    return [];
  }, [rolesEvaluation]);

  const KeepRowButtonRenderer = ({ api, node, data, setState, evaluation, setEvaluation }) => {
    const handleTransfer = () => {
      setState({ type: 'prepend', payload: [data] });
      setEvaluation({ ...evaluation, recommended_roles: evaluation.recommended_roles.filter(obj => obj.id !== data.id) });
  
      setTimeout(() => {
        api.setRowData(data);
      }, 0);
    };
  
    return (
      <span onClick={handleTransfer} className="button__icon button__icon-green material-symbols-outlined">
        add_circle
      </span>
    );
  };

  const generateRoles = async (
  ) => {
    if (isGeneratingRoles) return;

    if (!validateRoles(roles)) {
      alert('Ensure all current roles are valid before generating.');
      return;
    }

    setIsGeneratingUnits(true);
    const blankRow = addNewRole();
    setIsLoading({ type: 'add_to_list', payload: blankRow.id });

    const apiPayload = {
        'prompt_template': 'generate_roles',
        'client': client,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'mission_statement': missionStatement ? missionStatement : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'strategy_arena': strategyArena ? strategyArena : '',
        'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
        'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
        'strategy_staging': strategyStaging ? strategyStaging : '',
        'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
        'design_criteria': designCriteria ? designCriteria : [],
        'blueprint': blueprint ? blueprint : {},
        'functions': functions ? functions : [],
        'organization_units': organizationUnits ? organizationUnits : [],
        'roles': roles ? roles : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const generatedUnits = apiResponse.message;

      try {
        setRoles({ type: 'overwrite', payload: generatedUnits } );
        setIsGeneratingUnits(false);
      } catch (error) {
          console.error('Error parsing API response:', error);
          setIsGeneratingUnits(false);
      }

    } catch (error) {
        console.error('Error generating design criteria:', error);
    }
    setIsGeneratingUnits(false);
    setIsLoading({ type: 'remove_from_list', payload: blankRow.id });
    setRoles({ type: 'remove', payload: blankRow });
  };

const evaluateRoles = async (
  ) => {
    if (isGeneratingRoles) return;

    if (!validateRoles(roles)) {
      alert('Ensure all current roles are valid before evaluating.');
      return;
    }

    setIsEvaluatingRoles(true);

    const apiPayload = {
        'prompt_template': 'evaluate_roles',
        'client': client,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'mission_statement': missionStatement ? missionStatement : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'strategy_arena': strategyArena ? strategyArena : '',
        'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
        'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
        'strategy_staging': strategyStaging ? strategyStaging : '',
        'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
        'design_criteria': designCriteria ? designCriteria : [],
        'functions': functions ? functions : [],
        'organization_units': roles ? roles : [],
        'roles': roles ? roles : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const rolesEvaluation = apiResponse.message;

      try {
        setRolesEvaluation(rolesEvaluation);
      } catch (error) {
          console.error('Error parsing API response:', error);
      }

    } catch (error) {
        console.error('Error evaluating roles:', error);
    }
    setIsEvaluatingRoles(false);
  };

  const refineRoles = async (activity) => {
    return; 
    /*console.log('Splitting activity:', activity);
    setIsLoading({ type: 'add_to_list', payload: [activity.id] });
    const apiPayload = {
      'prompt_template': 'split_orgnaniazation_unit',
      'activity_to_split': activity.activity,
      'description': activity.description,
      'business_name': name ? name : '', 
      'business_website': website ? website : '',
      'industry': businessDetails.industry ? businessDetails.industry : '',
      'num_employees': businessDetails.num_employees ? businessDetails.num_employees : '',
      'revenue': businessDetails.revenue ? getRevenueFormated(businessDetails.revenue) : '',
      'designCriteria': designCriteria ? designCriteria : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const newdesignCriteria = apiResponse.message[0]['designCriteria'];
      return newdesignCriteria;
    } catch (error) {
      console.error('Error sending chat message:', error);
    } finally {
      setIsLoading({ type: 'remove_from_list', payload: [activity.id] });
    }*/
  }

  const validateRoles = (roleList) => {
    return roleList.every((role) => {
      return (
        role.name && role.name.trim() !== '' &&
        role.abbreviation && role.abbreviation.trim() !== '' &&
        role.purpose && role.purpose.trim() !== '' &&
        role.responsibilities && role.responsibilities.trim() !== ''
      );
    });
  };

  const handleImportSubmit = useCallback((data) => {
    setShowImport(false);
    let newRoles = [];
    data.rows.forEach((row) => {
      let newRow = {
        id: generateuniqueIshId(),
        name: row[data.name],
        abbreviation: row[data.abbreviation],
        purpose: row[data.purpose],
        responsibilities: row[data.responsibilities],
      }
      if (newRoles.find(f => f.id === newRow.id)) {
        return;
      }
      newRoles.push(newRow);
    })

    setRoles({ type: data.write_mode, payload: newRoles });
  }, [setRoles]);

  return (
    <div className="define-tab">
      <div className="function-tab-header">
      </div>
      
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <button 
            onClick={() => generateRoles()} 
            disabled={isGeneratingRoles} 
            className="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Generate Roles
            {isGeneratingRoles ? (
              <Loading style={{ marginLeft: 'auto', height: '35px' }} />
            ) : (
              <span className="material-symbols-outlined">neurology</span>
            )}
          </button>
          <button onClick={() => setShowImport(true)} className="button">
            Import Roles
            <span className="material-symbols-outlined">
              upload
            </span>
          </button>
          <ExportToExcel 
            buttonLabel='Export Roles' 
            data={roles} 
            fileName={name + ' Roles from Reconfig'} 
            sheetName={name + ' Roles'} 
            fieldsToExport={['name', 'abbreviation', 'purpose', 'responsibilities']}
            className="button"
          />
          <Button
            color="primary"
            onClick={() => addNewRole('normal')}
            text="Add Role"
            className="button"
            icon="plus.svg#plus"
          />
          <Modal isOpen={showImport} toggle={() => setShowImport(false)}>
            <ModalHeader>Import roles</ModalHeader>
            <CSVWizard
              toggle={() => setShowImport(false)}
              handleSubmit={handleImportSubmit}
              specifyColumns={[
                { key: 'name', label: 'Name', placeholder: 'Name', },
                { key: 'abbreviation', label: 'Abbreviation', placeholder: 'Abbreviation' },
                { key: 'purpose', label: 'purpose', placeholder: 'Purpose' },
                { key: 'responsibilities', label: 'Responsibilities', placeholder: 'Responsibilities' },
              ]}
            />
          </Modal>
      </div>
      <CollapsibleEvaluationAgGrid 
        objectName="Roles"
        evaluation={rolesEvaluation?.alignment_evaluation}
        evaluationQuality={rolesEvaluation?.alignment_evaluation_quality}
        coverage={rolesEvaluation?.coverage_evaluation}
        coverageQuality={rolesEvaluation?.coverage_evaluation_quality}
        columnDefs={evaluationColumns}
        rowData={evaluation_rowData}
        evaluationFunction={evaluateRoles}
        contextMenuGeneration={refineRoles}
        isLoading={isLoading}
        isEvaluating={isEvaluatingRoles}
        setIsEvaluating={setIsEvaluatingRoles}
        ValidatedData={validateRoles(roles)}
      />
      <div className="mt-6" style={{'margin': '10px'}}>
        <h2>Roles</h2>
      </div>
      <Table
        objectName="Role"
        listTitle="Roles"
        list={roles}
        setList={setRoles}
        columnDefs={roleColumns}
        rowData={rowData}
        contextMenuGeneration={refineRoles}
        isLoading={isLoading}
      />
    </div>
  );
}

export default RoleTab;
