import { Modal, ModalFooter, ModalBody } from 'reactstrap';
import Select from 'react-select';
import { isEmptyObject } from 'lib/helper';
import HTMLEditor from 'components/HTMLEditor';
import TextInput from 'components/TextInput';
import NumberInput from 'components/NumberInput';
import Button from 'components/Button';
import IndustrySelect from 'components/IndustrySelect';
import React, { useEffect, useState, useRef } from 'react';
import CurrencySelect from 'components/CurrencySelectByCountry';

import { Col, Row } from 'reactstrap';
import Loading from 'components/Loading';
import { postSearchApi } from 'api/chat';
import { set } from 'lodash';


export default function MissionTab({ client_id, name, setName, website, setWebsite, businessDetails, setBusinessDetails, missionStatement, setMissionStatement }) {
  const [isGeneratingDescription, setIsGeneratingDescription] = useState(false);
  const [errors, setErrors] = useState({ name: false, website: false });
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);

  const updateBusinessDetails = (field, value) => {
    setBusinessDetails({ ...businessDetails, [field]: value });
  };

  const updateIndustryHierarchy = (field, value) => {
    setBusinessDetails(prevDetails => {
      return {
        ...prevDetails,
        industry: {
          ...prevDetails.industry,
          [field]: value
        }
      };
    });
    
  };

  const generateMissionStatement = async () => {
    setWarningModalIsOpen(false);
    const newErrors = { name: !name, website: !website };
    setErrors(newErrors);
    if (newErrors.name || newErrors.website) {
      alert('Please fill in the business name and website before generating a mission statement.');
      return;
    }
    
    setIsGeneratingDescription(true);

    const apiPayload = {
        'prompt_template': 'generate_mission_statement',
        'client': client_id,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const generatedMissionStatement = apiResponse.message['mission_statement'];
      const generatedBusinessDetails = apiResponse.message['business_details'];

      try {
        setMissionStatement(generatedMissionStatement);
        setBusinessDetails(generatedBusinessDetails);
        setIsGeneratingDescription(false);
      } catch (error) {
          console.error('Error parsing API response:', error);
          setIsGeneratingDescription(false);
      }

    } catch (error) {
        console.error('Error generating business profile:', error);
    }
  };

  const checkIfEmptyBusinessDetails = () => {
    if (!missionStatement && isEmptyObject(businessDetails)) {
      return true;
    }
    return false;
  }

  return (
    <div>
      <div className="function-tab-header">
      </div>
      <Row className="align-items-center gap-5">
        <Col sm="3">
          <div className="form-label">
            Name
          </div>
          <TextInput
            placeholder="Business name"
            onChange={(e) => setName(e.target.value)}
            name="title"
            value={name || ''}
            autoFocus
            className={`${errors.name ? 'error-border' : ''}`}
          />
        </Col>
        <Col sm="4">
          <div className="form-label">
            Website
          </div>
          <TextInput
            placeholder="Business website"
            onChange={(e) => setWebsite(e.target.value)}
            name="website"
            value={website || ''}
            className={`${errors.website ? 'error-border' : ''}`}
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-left">
          <button onClick={() => checkIfEmptyBusinessDetails() ? generateMissionStatement() : setWarningModalIsOpen(true)} disabled={ isGeneratingDescription } className="button me-3 mb-3 w-auto mt-4">
          Generate Mission Statement
            <span className="material-symbols-outlined">
              neurology
            </span>
          </button>
        </Col>
      </Row>
      <div className="card-content mb-2">
        {isGeneratingDescription ? (
          <div className="loading-spin pb-5">
            <Loading text="AI is generating a mission statement for your business..." />
          </div>
        ) : (
        <span>
          {/*<Row className="d-flex justify-left mt-5 gap-5">
            <Col sm="4" className='card__content-wrapper'>
              <div className="form-header">
                Industry Hierarchy
              </div>
              <IndustrySelect
                industryHierarchy={businessDetails?.industry}
                updateIndustryHierarchy={updateIndustryHierarchy}
              />
            </Col>
          </Row>*/}
          <Row className="mt-2">
            <Col sm="12" className='card__content-wrapper mb-3'>
              <div className='mb-5'>
                <div className="form-header">
                  Mission Statement
                </div>
                <HTMLEditor
                  value={missionStatement}
                  onChange={(newValue) => {
                    setMissionStatement(newValue);
                  }}
                />
              </div>
            </Col>
          </Row>
        </span>
      )}
      {warningModalIsOpen && (
        <Modal isOpen={warningModalIsOpen} toggle={() => setWarningModalIsOpen(false)}>
          <ModalBody className='text-center p-3'>
            <div className="popup-warning-icon">
              <span className="material-symbols-outlined popup-warning-icon">
                warning
              </span>
            </div>
            <div className="popup-warning-header">
              Proceeding will overwrite the mission statement.
            </div>
            <div className="popup-warning-header">
              Are you certain you wish to continue?
            </div>
          </ModalBody>
          <ModalFooter>
            <button onClick={() => generateMissionStatement()} className="button ms-auto">
              Generate mission statement
              <span className="material-symbols-outlined">
                neurology
              </span>
            </button>
            <Button
              text="Cancel"
              onClick={() => setWarningModalIsOpen(false)}
              className="button ms-3"
            />
          </ModalFooter>
        </Modal>
      )}
      </div>
    </div>
  );
}
