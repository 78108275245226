import React from 'react';
import toast from 'lib/toast';
import { Form, FormGroup, Row } from 'reactstrap';
import ConfirmModal from './ConfirmModal';

export default function Modal(props) {
    const { showModal, modalName, objectName, onClose, onConfirm, user } = props;
    if (!showModal) return <></>;
  
    if (modalName === 'Delete') {
      return (
        <DeleteModal
          onConfirm={onConfirm}
          onClose={onClose}
          showModal={showModal}
          objectName={objectName}
        />
      );
    } else if (modalName === 'Invite') {
      return (
        <InviteUserModal
          onConfirm={onConfirm}
          onClose={onClose}
          showModal={showModal}
        />
      );
    } else if (modalName === 'Update') {
      if (objectName === 'client') {
        return (
          <UpdateClientModal
            onConfirm={onConfirm}
            onClose={onClose}
            showModal={showModal}
            data={user}
            objectName={objectName}
          />
        );
      }
      else {
        return (
          <UpdateUserModal
            onConfirm={onConfirm}
            onClose={onClose}
            showModal={showModal}
            data={user}
            objectName={objectName}
          />
        );
      }
    } else {
      return <></>;
    }
}

const InviteUserModal = (props) => {
    const onFormSubmit = (e) => {
      e.preventDefault();
      const form = new FormData(e.currentTarget);
      const payload = {
        first_name: form.get('first_name'),
        last_name: form.get('last_name'),
        email: form.get('email'),
        role: form.get('role'),
      }
      if (Object.values(payload).some(v => !v)) {
        toast('Please fill all fields');
        return;
      }
      props.onConfirm(payload);
    };
    return (
      <ConfirmModal {...props} hideButton>
        <Form onSubmit={onFormSubmit} className="text-start">
          <FormGroup>
            <label htmlFor="new_first_name">First Name</label>
            <input
              type="text"
              id="new_first_name"
              name="first_name"
              className="form-control"
              placeholder="First Name"
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="new_last_name">Last Name</label>
            <input
              type="text"
              id="new_last_name"
              name="last_name"
              className="form-control"
              placeholder="Last Name"
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="new_email">Email</label>
            <input
              type="text"
              id="new_email"
              name="email"
              className="form-control"
              placeholder="Email"
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="new_role" className="form-label">Role</label>
            <select className="form-select" id="new_role" name="role">
              <option value="admin">Admin</option>
              <option value="guest">User</option>
            </select>
          </FormGroup>
          <div className="text-center">
            <button
              type="submit"
              className="button"
            >
              Send Invite
            </button>
          </div>
        </Form>
      </ConfirmModal>
    );
  };
  const UpdateUserModal = (props) => {
    const onFormSubmit = (e) => {
      e.preventDefault();
      const form = new FormData(e.currentTarget);
      const payload = {
        role: form.get('role'),
      }
      props.onConfirm(payload);
    };
    const { data } = props;
    return (
      <ConfirmModal {...props} hideButton>
        <Form onSubmit={onFormSubmit} className="text-start">
          <p>Update role of <strong>{data.user__email}</strong></p>
          <FormGroup>
            <select
              className="form-select"
              id="new_role"
              name="role"
              defaultValue={data.is_admin ? 'admin' : 'guest'}
            >
              <option value="admin">Admin</option>
              <option value="guest">User</option>
            </select>
          </FormGroup>
          <div className="text-center">
            <button
              type="submit"
              className="button"
            >
              Update
            </button>
          </div>
        </Form>
      </ConfirmModal>
    );
  };
  const UpdateClientModal = (props) => {
    const onFormSubmit = (e) => {
      e.preventDefault();
      const form = new FormData(e.currentTarget);
      const payload = {
        client_id: props.data.id,
        client_name: form.get('client_name'),
      }
      props.onConfirm(payload);
    };
    const { data } = props;
    return (
      <ConfirmModal {...props} hideButton>
        <Form onSubmit={onFormSubmit} className="text-start">
          <p>Update name for client <strong>{data.name}</strong></p>
          <FormGroup>
            <input
              type="text"
              id="new_client_name"
              name="client_name"
              className="form-control"
              placeholder="Client Name"
              defaultValue={data.name}
            />
          </FormGroup>
          <div className="text-center">
            <button
              type="submit"
              className="button"
            >
              Update
            </button>
          </div>
        </Form>
      </ConfirmModal>
    );
  };
  const DeleteModal = (props) => {
    const { objectName } = props;
    return (
      <ConfirmModal {...props}>
        <p>Are you sure to delete this {objectName}?</p>
      </ConfirmModal>
    );
  };
  