import ApiHelper from './helper';

export async function getProfile() {
  const url = '/api/profile/';

  const { data } = await ApiHelper.get(url);
  return data;
}

export async function getClients() {
  const url = '/api/clients/';

  const { data } = await ApiHelper.get(url);
  return data;
}

export async function getUserList() {
  const url = '/api/users/';

  const { data } = await ApiHelper.get(url);
  return data;
}

export async function inviteUserList(payload) {
  const url = '/api/users/';

  const { data } = await ApiHelper.post(url, { data: payload });
  return data;
}

export async function deleteUser(payload) {
  const url = '/api/users/';

  const { data } = await ApiHelper.delete(url, { data: payload });
  return data;
}

export async function editUser(payload) {
  const url = '/api/users/';

  const { data } = await ApiHelper.patch(url, { data: payload });
  return data;
}

export async function createClient(name) {
  const url = '/api/clients/';

  const { data } = await ApiHelper.post(url, { data: { name } });
  return data;
}

export async function deleteClient(payload) {
  const url = '/api/clients/';

  const { data } = await ApiHelper.delete(url, { data: payload });
  return data;
}

export async function editClient(payload) {
  const url = '/api/clients/';

  const { data } = await ApiHelper.patch(url, { data: payload });
  return data;
}

export async function updateProfile(payload) {
  const url = '/api/profile/';

  const { data } = await ApiHelper.patch(url, { data: payload });
  return data;
}

export async function confirmEmail(token) {
  const url = '/api/email_verification/confirm/';

  const { data } = await ApiHelper.post(url, { data: { token } });
  return data;
}

export async function passwordReset(payload) {
  const url = '/api/profile/reset-password/';

  const { data } = await ApiHelper.post(url, { data: payload });
  return data;
}

export async function createScenario(params) {
  const url = '/api/scenario/';

  const { data } = await ApiHelper.post(url, { data: params });
  return data;
}

export async function changeScenario(params) {
  const url = '/api/scenario/';

  const { data } = await ApiHelper.patch(url, { data: params });
  return data;
}

export async function getScenarios(designId) {
  const url = `/api/scenario/?design=${designId}`;

  const { data } = await ApiHelper.get(url);
  return data;
}
