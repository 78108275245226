import React, { useState, useEffect, useRef } from 'react';

const ANIMATION_DURATION = '4s';

const CustomLoader = () => {
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 200, height: 200 }); // Default dimensions

  useEffect(() => {
    const observeTarget = containerRef.current;
    if (observeTarget) {
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          setDimensions({
            width: entry.contentRect.width,
            height: entry.contentRect.height
          });
        }
      });
      resizeObserver.observe(observeTarget);
      return () => resizeObserver.unobserve(observeTarget);
    }
  }, []);

  const size = Math.min(dimensions.width, dimensions.height);
  const limitedSize = Math.min(size, 300);
  const outerRadius = limitedSize;
  const innerRadius = limitedSize * 0.1;
  const deviation = limitedSize * 0.0533;

  return (
    <div ref={containerRef} style={{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <svg
        style={{ pointerEvents: 'none' }}
        width={outerRadius}
        height={outerRadius}
      >
        <defs>
          <filter id={`gooey-react-${outerRadius}`} colorInterpolationFilters="sRGB">
            <feGaussianBlur stdDeviation={deviation} />
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 96 -48" />
          </filter>
        </defs>
        <g style={{ filter: `url(#gooey-react-${outerRadius})` }}>
          <circle cx="50%" cy="30%" r={innerRadius} fill="#4963FE" />
          <circle cx="70%" cy="50%" r={innerRadius} fill="#34E39F" />
          <circle cx="50%" cy="70%" r={innerRadius} fill="#4963FE" />
          <circle cx="30%" cy="50%" r={innerRadius} fill="#34E39F" />
          <circle
            cx="50%"
            cy="30%"
            r={innerRadius}
            fill="#A033FF"
            style={{ 
              transformOrigin: 'center center', 
              animation: `rotate ${ANIMATION_DURATION} linear infinite` 
            }}
          />
          <circle
            cx="50%"
            cy="70%"
            r={innerRadius}
            fill="#A033FF"
            style={{ 
              transformOrigin: 'center center', 
              animation: `rotate ${ANIMATION_DURATION} linear infinite` 
            }}
          />
        </g>
      </svg>
    </div>
  );
};

const Loading = ({ text, style, className }) => {
  const defaultStyle = { textAlign: 'center', width: '100%', height: '100%' };
  const combinedStyle = style ? style : defaultStyle;
  const combinedClasses = className ? className : '';

  return (
    <div className={combinedClasses} style={combinedStyle}>
      <CustomLoader />
      <h3 style={{ textAlign: 'center' }}>{text}</h3>
    </div>
  );
};


export default Loading;
